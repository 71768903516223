<template>
  <div class="page">
    <div v-if="displayBreadcrumbs" class="page__breadrumbs container">
      <pt-breadcrumbs />
    </div>

    <page-header-fallback v-if="displayPageTitle">
      {{ currentPage.title }}
    </page-header-fallback>

    <div class="page__sections">
      <render-blocks v-if="currentPage && currentPage.sections" :sections="currentPage.sections" />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed } from '#imports';
import { usePagesStore } from '~/stores/pages';
import RenderBlocks from '~/components/content/RenderBlocks.vue';
import PtBreadcrumbs from '~/components/global/elements/PtBreadcrumbs.vue';
import { usePages } from '~/composables/pages';
import PageHeaderFallback from '~/components/global/components/page/PageHeaderFallback.vue';

const pages = usePagesStore();
const { displayBreadcrumbs, displayPageTitle } = usePages();
const currentPage = computed(() => pages.currentPage);
</script>
