<template>
  <template-component :key="route.fullPath" />
</template>

<script lang="ts" setup>
import { withTrailingSlash } from 'ufo';
import { navigateTo } from '#app';
import { definePageMeta, useAsyncData, useRoute } from '#imports';
import { usePagesStore } from '~/stores/pages';
import TemplateComponent from '~/components/templates/TemplateComponent.vue';
import { useLivePreview } from '~/composables/live-preview';
import { DEFAULT_LAYOUT } from '~/constants/layout';

const route = useRoute();
const pages = usePagesStore();
const { getLivePreviewHashFromPath, handleLivePreviewMode } = useLivePreview();
const livePreviewHash = getLivePreviewHashFromPath(route);

const { data: page } = await useAsyncData(
  route.path,
  async () => {
    return pages.getPage(route.path);
  },
  {
    watch: [route],
  }
);

pages.setPage(route.path, page.value);

onMounted(() => {
  handleLivePreviewMode(livePreviewHash);
});

if (pages.currentPage && pages.currentPage.redirect) {
  navigateTo(withTrailingSlash(pages.currentPage.redirect), { external: true });
}

definePageMeta({
  key: (route) => route.fullPath,
  pageType: 'page',
  layout: DEFAULT_LAYOUT,
});
</script>
