<template>
  <div class="home">
    <render-blocks v-if="currentPage && currentPage.sections" :sections="currentPage.sections" />
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import RenderBlocks from '~/components/content/RenderBlocks.vue';
import { usePagesStore } from '~/stores/pages';

const pages = usePagesStore();
const currentPage = computed(() => pages.currentPage);
</script>
