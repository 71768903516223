<template>
  <div class="page page-search" :class="{ 'has-breadcrumbs': displayBreadcrumbs }">
    <div class="container">
      <search-form />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { usePages } from '~/composables/pages';
import SearchForm from '~/components/global/components/search/SearchForm.vue';

const { displayBreadcrumbs } = usePages();
</script>
