<template>
  <div>
    <component :is="currentPageTemplate" v-if="currentPageTemplate" />
    <template-error v-else />
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, showError } from '#imports';
import { usePagesStore } from '~/stores/pages';
import Pages from '~/components/templates/Pages.vue';
import TemplateError from '~/components/templates/TemplateError.vue';
import Home from '~/components/templates/Home.vue';
import Resources from '~/components/templates/Resources.vue';
import Search from '~/components/templates/Search.vue';
import Grants from '~/components/templates/Grants.vue';
import GrantsIndex from '~/components/templates/GrantsIndex.vue';

export default defineComponent({
  components: {
    TemplateError,
    Pages,
    Home,
    Resources,
    Search,
    Grants,
    GrantsIndex,
  },

  setup() {
    const pages = usePagesStore();
    const currentPageTemplate = computed(() => pages.currentPageTemplate);

    if (!currentPageTemplate.value) {
      showError({ statusCode: 404, statusMessage: 'Page Not Found' });
    }
    return {
      currentPageTemplate,
    };
  },
});
</script>
